{
    "Dashboard": "Dashboard",
    "Logistics Managment": "Lojistik Yönetimi",
    "Create Post": "Gönderi Oluştur",
    "Post List": "Gönderi Listesi",
    "Query Shipping": "Gönderi Sorgula",
    "Price Calculation": "Fiyat Hesaplama",
    "Wallet Balance": "Cüzdan Bakiyesi",
    "Desi Cargo Regions": "Desi Kargo Bölgeleri",
    "Desi Cargo Country": "Desi Kargo Ülkesi",
    "Cargo Companies": "Kargo Şirketleri",
    "Logistics Integration": "Lojistik Entegrasyonu",
    "Customs List": "Gümrük Listesi",
    "Logistics Customers": "Lojistik Müşterileri",
    "Setting": "Ayarlar",
    "Desi Regions": "Desi Bölgeleri",
    "Cargo Country": "Kargo Ülkesi",
    "Loading": "Yükleniyor",
    "Cargo Region List": "Kargo Bölge Listesi",
    "Desi Price Definition": "Desi Fiyat Tanımı",
    "Dealers and discounts":"Bayiler ve indirimler",
    "Wallet Transactions":"Cüzdan İşlemleri",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "$vuetify": {
        "badge": "Rozet",
        "noDataText": "Veri yok",
        "close": "Kapat",
        "open": "Açık",
        "loading": "Yükleniyor",
        "carousel": {
            "ariaLabel": {
                "delimiter": "ayraç"
            }
        },
        "dataIterator": {
            "loadingText": "Öğe yükleniyor..."
        },
        "dataFooter": {
            "itemsPerPageText": "Sayfa başına öğe:",
            "itemsPerPageAll": "Hepsi",
            "pageText": "{0}-{1} / {2}",
            "firstPage": "İlk Sayfa",
            "prevPage": "Önceki Sayfa",
            "nextPage": "Sonraki Sayfa",
            "lastPage": "Son Sayfa"
        },
        "pagination": {
            "ariaLabel": {
                "root": "kök",
                "previous": "önceki",
                "first": "ilk",
                "last": "son",
                "next": "sonraki",
                "currentPage": "mevcut sayfa",
                "page": "sayfa"
            }
        },
        "input": {
            "clear": "temizle",
            "appendAction": "ekleme işlemi",
            "prependAction": "ön ekleme işlemi",
            "counterSize": "sayaç boyutu",
            "otp": "tek kullanımlık şifre"
        },
        "fileInput": {
            "counterSize": "sayaç boyutu"
        },
        "rating": {
            "ariaLabel": {
                "item": "öğe"
            }
        }
    }
}
