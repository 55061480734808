
<script setup>
definePage({
  meta: {
    layout: 'blank',
  },
})
</script>

<template>
  <div />
</template>
