<script setup>
import { requiredValidator } from '@/@core/utils/validators'
import { ref } from 'vue'

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const isPasswordVisible = ref(false)
const route = useRoute()
const router = useRouter()
const ability = useAbility()

const refVForm = ref()
const isError = ref(false)
const isErrorMessage = ref('')

const credentials = ref({
  email: null,
  password: null,
})

const isLoading = ref(false)

const login = async () => {
  isLoading.value = true
  try {
    await $api('/login', {
      method: 'POST',
      body: {
        email: credentials.value.email,
        password: credentials.value.password,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        console.log(response)
        if (response.status==403) {
          isErrorMessage.value = response._data.message
          isError.value = true
        } else {
          const { accessToken, userData, userAbilityRules } = response._data

          useCookie('userAbilityRules').value = userAbilityRules
          ability.update(userAbilityRules)
          useCookie('userData').value = userData
          useCookie('accessToken').value = accessToken
          await nextTick(() => {
            router.replace(route.query.to ? String(route.query.to) : '/')
          })
        }
      },
    })
  } catch (error) {
    console.error(error)
  }
  isLoading.value = false
}

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) login()
  })
}

const onRegister = () => {
  router.push({ name: 'register' })
}
</script>


<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VCard
        class="auth-card pa-4"
        max-width="448"
        min-width="348"
      >
        <VCardTitle class="text-center ma-2">
          LOGISTICS CARGO
        </VCardTitle>

        <VCardText>
          <VForm
            ref="refVForm"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <VTextField
                  v-model="credentials.email"
                  label="Account"
                  placeholder="Email address"
                  type="name"
                  :rules="[emailValidator]"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VTextField
                  v-model="credentials.password"
                  class="mb-2"
                  label="Password"
                  required
                  placeholder="Enter your password"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
                <!--
                  <VCard class="mb-4 mt-1" color="surface-variant" variant="tonal">
                  <VCardText class="text-medium-emphasis text-caption">
                  Warning: After 3 consecutive failed login attempts, you account will be
                  temporarily locked for three
                  hours. If you must login now, you can also click "Forgot login
                  password?" below
                  to reset the login
                  password.
                  </VCardText>
                  </VCard>
                -->

                <!-- login button -->
                <VBtn
                  :loading="isLoading"
                  class="mb-1"
                  :disabled="isLoading"
                  block
                  type="submit"
                >
                  Log In
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol
                cols="12"
                class="text-center text-base"
              />
            </VRow>
          </VForm>
        </VCardText>

        <VCardText class="text-center">
          <VBtn
            variant="text"
            class="text-blue text-decoration-none"
            @click="onRegister"
          >
            Sign Up Now
          </VBtn>
        </VCardText>
      </VCard>
    </div>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="isErrorMessage"
      :no-enable="false"
    />
  </div>
</template>

<style lang="scss">
@use '@core-scss/template/pages/page-auth.scss';
</style>
