
// 👉 Redirects
export const redirects = [
  {
    path: '/',
    name: 'index',
    redirect: to => {
      const userData = useCookie('userData')
      if (userData){
        return { name: 'dashboard' }
      }

      return { name: 'login', query: to.query }
    },
  },

]
export const routes = [
]
