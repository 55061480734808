import { canNavigate } from '@layouts/plugins/casl'

export const setupGuards = router => {
  router.beforeEach((to, from, next) => {
    try {

      // console.log(to.meta)
      if (to.meta.public) {

        return next()
      }

      const userData = useCookie('userData').value
      const accessToken = useCookie('accessToken').value
      const isLoggedIn = !!(userData && accessToken)


      if (to.meta.unauthenticatedOnly) {
        if (isLoggedIn) {

          return next('/')
        } else {

          return next()
        }
      }

      if (!canNavigate(to) && to.matched.length) {

        if (isLoggedIn) {

          return next({ name: 'not-authorized' })
        } else {


          return next({
            name: 'login',
            query: {
              ...to.query,
              to: to.fullPath !== '/' ? to.fullPath : undefined,
            },
          })
        }
      }

      next()
    } catch (error) {
      console.error('Error in navigation guard:', error)
      next(error)
    }
  })
}
