<script setup>
import { requiredValidator } from '@/@core/utils/validators'
import { onMounted } from 'vue'
import { VAutocomplete } from 'vuetify/lib/components/index.mjs'
import { fakeAwait } from '@/utils'


const isError = ref(false)
const isErrorMessage = ref('')
const isLoading = ref(false)
const router = useRouter()
const refVForm = ref()
const isSend= ref(false)

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const regions= ref([])
const countries= ref([])
const cities= ref([])
const districts= ref([])
const currencies= ref([])

const registrationTypes = [
  { title: 'Instutional Register', value: 0 },
  { title: 'Indivudial Register', value: 1 },
]

const useTypes = [
  { title: 'Customer', value: 0 },
  { title: 'Dealer', value: 1 },
]

const companyTypes = ref([])

const formData = ref({
  registration_type:null,
  use_type: null,
  name: null,
  surname: null,
  email: null,
  username: null,
  password: null,
  password_confirmation: null,
  government_id: null,
  address:{
    region_id: null,
    address: null,
    country_id: null,
    city_id: null,
    district_id: null,
    phone: null,
  },
  bank:{
    account_name: null,
    bank_name: null,
    currency_id: null,
    iban: null,
  },
  company:{
    trade_title: null,
    registration_number: null,
    company_type: null,
    mersis_number: null,
    tax_office: null,
    tax_number: null,
  },


})


const getCompanyTypes= async () => {
  try {
    await $api('/get_company_types', {
      method: 'GET',
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        companyTypes.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
}

const getCurrencies= async () => {
  try {
    await $api('/get_allcurencies', {
      method: 'GET',
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        currencies.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
}

const getRegions= async () => {
  try {
    await $api('/get_regions', {
      method: 'GET',
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        regions.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  getRegions()
  getCurrencies()
  getCompanyTypes()
})

const onSubmit = async() => {
  refVForm.value?.validate().then(async ({ valid: isValid }) => {
    if (isValid) {
      console.log('form', formData.value)
      isLoading.value = true
      try {
        await $api(`/register`, {
          method: 'POST',
          body: formData.value,
          onResponseError({ response }) {
            console.log(response)
          },
          async onResponse({ response }) {
            if(response.status>399&&response.status<500){
              isError.value=true
              isErrorMessage.value=response._data.message
            }else if(response.status==200){
              isSend.value=true
            }

          },
        })
      } catch (error) {
        console.error(error)
      }
      isLoading.value = false

    }
  })
}

const onRegister = () => {
  router.push({ name: 'login' })
}

const onUpdateRegion= async () => {
  try {
    await $api(`/get_countries`, {
      method: 'GET',
      params:{
        region_id: formData.value.address.region_id,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {

        countries.value = response._data
        formData.value.address.country_id=null
      },
    })
  } catch (error) {
    console.error(error)
  }
}


const onUpdateCountry= async () => {
  try {
    await $api(`/get_cities`, {
      method: 'GET',
      params:{
        country_id: formData.value.address.country_id,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        cities.value = response._data
        formData.value.address.city_id=null
      },
    })
  } catch (error) {
    console.error(error)
  }
}


const onUpdateCity= async () => {
  try {
    await $api(`/get_districts`, {
      method: 'GET',
      params:{
        city_id: formData.value.address.city_id,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        districts.value = response._data
        formData.value.address.district_id=null
      },
    })
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VCard
        v-if="!isSend"
        class="pa-4"
        max-width="848"
        min-width="655"
      >
        <div class="text-h4 mb-2">
          Logistics registration application
        </div>
        <VForm
          ref="refVForm"
          @submit.prevent="onSubmit"
        >
          <VRow>
            <VCol
              cols="12"
              :md="formData.registration_type!=null? 6 : 12"
            >
              <VSelect
                v-model="formData.registration_type"
                label="Registration type"
                :items="registrationTypes"
                clearable
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol
              v-if="formData.registration_type!=null"
              cols="12"
              md="6"
            >
              <VSelect
                v-model="formData.use_type"
                label="Use type"
                :items="useTypes"
                clearable
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>
          <div v-if="formData.registration_type!=null">
            <VRow>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.name"
                  label="Name"
                  :rules="[requiredValidator]"
                />
              </VCol>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.surname"
                  label="Surname"
                  :rules="[requiredValidator]"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.email"
                  label="E-Mail"
                  :rules="[requiredValidator,emailValidator]"
                />
              </VCol>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.username"
                  label="Username"
                  :rules="[requiredValidator]"
                />
              </VCol>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.password"
                  label="Password"
                  type="password"
                  :rules="[requiredValidator,passwordValidator]"
                />
              </VCol>
              <VCol
                cols="12"
                md="6"
              >
                <VTextField
                  v-model="formData.password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  :rules="[requiredValidator,passwordValidator]"
                />
              </VCol>
            </VRow>
            <VRow class="mb-2">
              <VCol>
                <VTextField
                  v-model="formData.government_id"
                  label="TC VKN"
                  :rules="[requiredValidator]"
                />
              </VCol>
            </VRow>
            <div class="border rounded-sm pa-1 mt-1">
              <div class="text-h5 mb-2">
                Address
              </div>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSelect
                    v-model="formData.address.region_id"
                    label="Region"
                    :items="regions"
                    item-title="short_code"
                    item-value="id"
                    :rules="[requiredValidator]"
                    @update:model-value="onUpdateRegion"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSelect
                    v-model="formData.address.country_id"
                    label="Country"
                    :items="countries"
                    item-title="trisim"
                    item-value="id"
                    :rules="[requiredValidator]"
                    @update:model-value="onUpdateCountry"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSelect
                    v-model="formData.address.city_id"
                    label="City"
                    item-title="name"
                    item-value="id"
                    :items="cities"
                    :rules="[requiredValidator]"
                    @update:model-value="onUpdateCity"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSelect
                    v-model="formData.address.district_id"
                    label="District"
                    item-title="name"
                    item-value="id"
                    :items="districts"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.address.phone"
                    label="Phone"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
              <VTextarea
                v-model="formData.address.address"
                class="mt-2 mb-2"
                label="Address"
                rows="3"
                :rules="[requiredValidator]"
              />
            </div>
            <div
              v-if="formData.registration_type==0 "
              class="border rounded-sm pa-1 mt-1"
            >
              <div class="text-h5 mb-2">
                Company
              </div>

              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.company.trade_title"
                    label="Trade Title"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.company.registration_number"
                    label="Registration Number"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VSelect
                    v-model="formData.company.company_type"
                    label="Company Type"
                    :items="companyTypes"
                    item-title="name"
                    item-value="id"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.company.mersis_number"
                    label="Mersis Number"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.company.tax_office"
                    label="Tax Office"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.company.tax_number"
                    label="Tax Number"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
            </div>
            <div class="border rounded-sm pa-1 mt-1">
              <div class="text-h5 mb-2">
                Bank
              </div>


              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.bank.account_name"
                    label="Account Name"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.bank.bank_name"
                    label="Bank Name"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VAutocomplete
                    v-model="formData.bank.currency_id"
                    label="Currency"
                    :items="currencies"
                    item-title="code"
                    item-value="id"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="formData.bank.iban"
                    label="IBAN"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
            </div>
            <div class="text-center ma-2">
              <VBtn type="submit">
                Send
              </VBtn>
            </div>
          </div>
        </VForm>
        <VCardText class="text-center">
          <VBtn
            variant="text"
            class="text-blue text-decoration-none"
            @click="onRegister"
          >
            I am already a member
          </VBtn>
        </VCardText>
        <VOverlay
          v-model="isLoading"
          contained
          persistent
          class="align-center justify-center"
        >
          <VProgressCircular indeterminate />
        </VOverlay>
      </VCard>
      <VExpandXTransition>
        <VCard
          v-show="isSend"

          height="348"
          width="300"
          class="auth-card pa-4 text-center"
        >
          <VCardText>
            <VBtn
              icon
              variant="outlined"
              color="success"
              class="mb-4"
              style="width: 88px; height: 88px; pointer-events: none;"
            >
              <span class="text-5xl"><VIcon icon="tabler-check" /></span>
            </VBtn>

            <h6 class="text-lg font-weight-medium">
              The registration process is successful and you can log in.
            </h6>
          </VCardText>
          <VCardText class="text-center">
            <VBtn
              variant="text"
              class="text-blue text-decoration-none"
              @click="onRegister"
            >
              Login
            </VBtn>
          </VCardText>
        </VCard>
      </VExpandXTransition>
    </div>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="isErrorMessage"
      :no-enable="false"
    />
  </div>
</template>

<style lang="scss">
@use '@core-scss/template/pages/page-auth.scss';
</style>
