{
    "Dashboard": "Tableau de bord",
    "Logistics Managment": "Gestion logistique",
    "Create Post": "Créer un post",
    "Post List": "Liste des posts",
    "Query Shipping": "Requête d'expédition",
    "Price Calculation": "Calcul du prix",
    "Wallet Balance": "Solde du portefeuille",
    "Desi Cargo Regions": "Régions de fret Desi",
    "Cargo Companies": "Compagnies de fret",
    "Logistics Integration": "Intégration logistique",
    "Customs List": "Liste des douanes",
    "Logistics Customers": "Clients logistiques",
    "Setting": "Paramètres",
    "Desi Regions": "Régions volumétriques",
    "Cargo Country": "Pays de fret",
    "Loading": "Chargement",
    "Cargo Region List": "Liste des régions de fret",
    "Desi Price Definition": "Définition du prix Desi",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "$vuetify": {
        "badge": "Badge",
        "loading": "Chargement",
        "noDataText": "Pas de données disponibles",
        "close": "Fermer",
        "open": "Ouvert",
        "carousel": {
            "ariaLabel": {
                "delimiter": "délimiteur"
            }
        },
        "dataFooter": {
            "itemsPerPageText": "Objets par page:",
            "itemsPerPageAll": "Tout",
            "pageText": "{0}-{1} of {2}",
            "firstPage": "Première page",
            "prevPage": "Page précédente",
            "nextPage": "Page suivante",
            "lastPage": "Dernière page"
        },
        "pagination": {
            "ariaLabel": {
                "root": "racine",
                "previous": "précédente",
                "first": "d'abord",
                "last": "dernière",
                "next": "suivante",
                "currentPage": "page actuelle",
                "page": "page"
            }
        },
        "input": {
            "clear": "dégager",
            "appendAction": "ajouter une action",
            "prependAction": "préfixer l'action",
            "otp": "otp"
        },
        "fileInput": {
            "counterSize": "Taille du compteur"
        },
        "rating": {
            "ariaLabel": {
                "item": "Objet"
            }
        }
    }
}