{
    "Dashboard": "لوحة التحكم",
    "Logistics Managment": "إدارة الخدمات اللوجستية",
    "Create Post": "إنشاء منشور",
    "Post List": "قائمة المنشورات",
    "Query Shipping": "استعلام الشحن",
    "Price Calculation": "حساب السعر",
    "Wallet Balance": "رصيد المحفظة",
    "Desi Cargo Regions": "مناطق ديسي للشحن",
    "Cargo Companies": "شركات الشحن",
    "Logistics Integration": "تكامل الخدمات اللوجستية",
    "Customs List": "قائمة الجمارك",
    "Logistics Customers": "عملاء الخدمات اللوجستية",
    "Setting": "الإعدادات",
    "Desi Regions": "مناطق الحجم",
    "Cargo Country": "دولة الشحن",
    "Loading": "جار التحميل",
    "3": "3",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "$vuetify": {
        "badge": "شارة",
        "noDataText": "لا تتوافر بيانات",
        "close": "قريب",
        "open": "افتح",
        "loading": "جار التحميل",
        "carousel": {
            "ariaLabel": {
                "delimiter": "تحديد"
            }
        },
        "dataFooter": {
            "itemsPerPageText": "مواد لكل صفحة:",
            "itemsPerPageAll": "الجميع",
            "pageText": "{0} - {1} من {2}",
            "firstPage": "الصفحة الأولى",
            "prevPage": "الصفحة السابقة",
            "nextPage": "الصفحة التالية",
            "lastPage": "آخر صفحة"
        },
        "pagination": {
            "ariaLabel": {
                "root": "جذر",
                "previous": "السابق",
                "first": "أولاً",
                "last": "آخر",
                "next": "التالي",
                "currentPage": "الصفحه الحاليه",
                "page": "صفحة"
            }
        },
        "input": {
            "clear": "صافي",
            "appendAction": "إلحاق الإجراء",
            "prependAction": "قبل العمل",
            "otp": "أوتب"
        },
        "fileInput": {
            "counterSize": "حجم العداد"
        },
        "rating": {
            "ariaLabel": {
                "item": "العنصر"
            }
        }
    }
}